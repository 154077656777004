import * as React from "react";
import { navigate, PageProps } from "gatsby";
import isBrowser from "../../utils/isBrowser";

export default ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const instaHandle = params.get("para");

  isBrowser() && // avoid server-side rendering problems
    navigate("/secretos", {
      state: { attribution: "Alejandra", instaHandle: instaHandle },
      replace: true, // replace history instead of push
    });

  return <></>;
};
